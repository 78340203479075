'use client';

import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { m } from 'framer-motion';
import cn from '@/utils/cn';
interface ProgressProps {
  data: {
    name: string;
    percent?: number;
  };
  className?: string;
}
const Progress_1 = _compiledBlock(_props => <div className={_props.v0}>
      <div className={_props.v1}>{_props.v2}</div>
      <div className={_props.v3}>
        {_props.v4}
      </div>
      <div className={_props.v5}>{_props.v6}%</div>
    </div>, {
  name: "Progress_1",
  portals: ["v2", "v4", "v6"]
});
const Progress = ({
  data,
  className
}: ProgressProps) => {
  const {
    name,
    percent = 0
  } = data;
  const variants = {
    initial: {
      width: 0
    },
    animate: {
      width: `${percent}%`,
      transition: {
        delay: 0.8
      }
    }
  };
  return /*@million jsx-skip*/<Progress_1 v0={cn('flex items-center justify-between gap-3')} v1={cn('w-24')} v2={name} v3={cn('relative flex h-3 flex-1 justify-center rounded-full bg-muted')} v4={<m.span initial="initial" animate="animate" variants={variants} className={cn('absolute left-0 top-0 h-3 rounded-full px-3', className)}>
          &ensp;
        </m.span>} v5={cn('w-8 text-right')} v6={percent.toFixed(0)} />;
};
export default Progress;