'use client';

import { compiledBlock as _compiledBlock } from "million/react";
import { block as _block } from "million/react";
import { m } from 'framer-motion';
import { useState } from 'react';
import cn from '@/utils/cn';
interface TooltipProps {
  title: string;
  children: React.ReactNode;
}
const Tooltip_1 = _compiledBlock(_props => <div className={_props.v0}>
      <div className={_props.v1} onMouseEnter={_props.v2} onMouseLeave={_props.v3}>
        {_props.v4}
      </div>
      {_props.v5}
    </div>, {
  name: "Tooltip_1",
  portals: ["v4", "v5"]
});
const Tooltip = ({
  title,
  children
}: TooltipProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const onMouseEnter = () => {
    setVisible(true);
  };
  const onMouseLeave = () => {
    setVisible(false);
  };
  const variants = {
    hide: {
      opacity: 0,
      y: -10
    },
    show: {
      opacity: 1,
      y: 0
    }
  };
  return /*@million jsx-skip*/<Tooltip_1 v0={cn('relative inline-block')} v1={cn('relative')} v2={onMouseEnter} v3={onMouseLeave} v4={children} v5={visible && <m.div className={cn('absolute bottom-full mb-2 block w-max max-w-xs rounded bg-accent px-2 py-1 text-xs font-medium text-accent-foreground')} variants={variants} initial="hide" animate="show">
          {title}
        </m.div>} />;
};
export default Tooltip;